import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class ThankYou extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title

        return (
            <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <div>
          <div class="home-atf">
            <div class="container" id="atf-h1">
              <h1 id="atf" class="h1">
                THANK YOU
              </h1>
              <p class="atf-subheading">
                We'll be in contact with you soon
              </p>
              <a class="btn btn-primary atf" href="/">
                BACK TO HOME
              </a>
            </div>
          </div>
        </div>
      </Layout>
        )
    }
}

export default ThankYou

export const ThankYouPageQuery = graphql `
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`